var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-transition",
    {
      attrs: {
        "animation-duration": _vm.animationDuration,
        appear: "",
        disabled: _vm.isAnimationDisabled,
        "enter-animation": _vm.enterAnimation,
        "leave-animation": _vm.leaveAnimation,
        mode: _vm.animationMode,
      },
    },
    [_c("router-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }