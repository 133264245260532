var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: _vm.rootStyles },
    [
      _vm.disabled
        ? _vm._t("default")
        : _vm.isGroupTransition
        ? _c(
            "transition-group",
            {
              attrs: {
                appear: _vm.appear,
                "leave-active-class": _vm.leaveActiveClass,
                "leave-from-class": _vm.leaveFromClass,
                "leave-to-class": _vm.leaveToClass,
                name: _vm.enterAnimation,
              },
            },
            [_vm._t("default")],
            2
          )
        : _c(
            "transition",
            {
              attrs: {
                appear: _vm.appear,
                "leave-active-class": _vm.leaveActiveClass,
                "leave-from-class": _vm.leaveFromClass,
                "leave-to-class": _vm.leaveToClass,
                mode: _vm.mode,
                name: _vm.enterAnimation,
              },
            },
            [_vm._t("default")],
            2
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }